// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: "ar",
    // detection: {
    //   order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
    //   caches: ['cookie'],
    // },
    backend: {
      loadPath: '/locale/{{lng}}.json',
    },
    react: {
      useSuspense: false, // Set to true if you want to use suspense
    },
  });

i18n.changeLanguage('ar')

export default i18n;
