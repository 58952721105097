import React from "react";
import "./contact.css";
import { useTranslation } from "react-i18next";
import CallIcon from "@mui/icons-material/Call";
import FaxIcon from "@mui/icons-material/Fax";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="contact">
      <div className="contact_container">
        <h3>{t("contactUs")}</h3>
        <div className="contact_sections">
          <div className="contact_section">
            <CallIcon className="ico" />
            <p>+90 532 283 02 40</p>
          </div>
          <div className="contact_section">
            <FaxIcon className="ico" />
            <p>+90 324 239 07 70 pdx</p>
          </div>
          <div className="contact_section">
            <AlternateEmailIcon className="ico" />
            <p>info@ozkayaltd.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
