import React from "react";
import "./location.css";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function Location() {
  const { t } = useTranslation();
  return (
    <div className="location">
      <div className="location_container">
        <h3>{t("location")}</h3>
        <div className="location_sections">
          <div className="location_section">
            <div className="sec_city">
              <LocationOnIcon className="locIco" />
              <h4>Turkey - Mersin</h4>
            </div>
            <p>Barbur Apt. No: 138/3</p>
            <p>33010 Mersin - Turkey</p>
          </div>

          {/* <div className="location_section">
            <div className="sec_city">
              <LocationOnIcon className="locIco" />
              <h4>Iraq - Erbil</h4>
            </div>
            <p>English Village</p>
            <p>No 204 – Erbil</p>
          </div>

          <div className="location_section">
            <div className="sec_city">
              <LocationOnIcon className="locIco" />
              <h4>Iraq - Mosul</h4>
            </div>
            <p>Near Dijla City, Luna Park</p>
            <p>Shalalat, – Mousul</p>
          </div>

          <div className="location_section">
            <div className="sec_city">
              <LocationOnIcon className="locIco" />
              <h4>Iraq - Duhok</h4>
            </div>
            <p>In Front Of Big Mosque</p>
            <p>Nohedra Str. – Duhok</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Location;
