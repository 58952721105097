import React, { useState } from "react";
import "./services.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import service_1 from "../../assets/images/service_1.png";
import service_2 from "../../assets/images/service_2.png";
import service_3 from "../../assets/images/service_3.png";

import logs from "../../assets/images/logs.png";
function Services() {
  const { t } = useTranslation();
  let location = useLocation();
  const [tapActive, setTapActive] = useState(1);

  const setTapActiveClick = (tatNumber) => {
    setTapActive(tatNumber);
    console.log(tatNumber);
  };
  return (
    <div className="services">
      <div className="services_container">
        <h3>
          {location.pathname !== "/ar" ? t("ozkaya") : t("services")}{" "}
          <span>
            {location.pathname !== "/ar" ? t("services") : t("ozkaya")}
          </span>
        </h3>
        <div className="services_tap">
          <div className="tap" id="1" onClick={() => setTapActiveClick(1)}>
            <span className={tapActive == 1 ? "tapSpan_active" : "tapSpan"}>
              {t("LogisticsNetwork")}
            </span>
          </div>
          <div className="tap" id="2" onClick={() => setTapActiveClick(2)}>
            <span className={tapActive == 2 ? "tapSpan_active" : "tapSpan"}>
              {t("SupplyDistribute")}
            </span>
          </div>
          <div className="tap" id="3" onClick={() => setTapActiveClick(3)}>
            <span className={tapActive == 3 ? "tapSpan_active" : "tapSpan"}>
              {t("TransitTrading")}
            </span>
          </div>
        </div>
        <div
          className={
            tapActive == 1
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("LogisticsNetworkTxt")}</p>
          <div className="services_image_content">
            <img src={logs} alt="service_1" />
          </div>
        </div>
        <div
          className={
            tapActive == 2
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("SupplyDistributeTxt")}</p>
          <div className="services_image_content">
            <img src={service_2} alt="service_2" />
          </div>
        </div>
        <div
          className={
            tapActive == 3
              ? location.pathname !== "/ar"
                ? "services_section"
                : "services_section_ar"
              : "services_section_hide"
          }
        >
          <p>{t("TransitTradingTxt")}</p>
          <div className="services_image_content">
            <img src={service_3} alt="service_2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
