import React from "react";
import "./about.css";
import mena from "../../assets/images//menaimg.jpg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function About() {
  let location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="aboutus">
      <div className="about_containre">
        <h3>
          {t("aboutozkayaAbout")}
          <span>{t("aboutozkaya")}</span>
        </h3>
        <div className="about_text_content">
          <p>{t("aboutText")}</p>
        </div>
        <div className="about_image_content">
          <img src={mena} alt="mena" />
        </div>
        <div
          className={
            location.pathname !== "/ar" ? "about_sections_en" : "about_sections"
          }
        >
          <div
            className={
              location.pathname !== "/ar" ? "about_section_en" : "about_section"
            }
          >
            <h4>{t("vision")}</h4>
            <p>{t("visionText")}</p>
          </div>
          <div
            className={
              location.pathname !== "/ar" ? "about_section_en" : "about_section"
            }
          >
            <h4>{t("mission")}</h4>
            <p>{t("missionText")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
