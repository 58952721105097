import React, { useState } from "react";
import "./products.css";
import { Radio, Space, Tabs, Carousel } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import legQuarter_small from "../../assets/products_images/Chicken-LegQuarter_small.png";
import griller_small from "../../assets/products_images/Griller_small.png";
import chicken_Breast_small from "../../assets/products_images/Chicken_Breast_small.png";
import drumstick from "../../assets/products_images/turkey-drumstick.png";
import breast_with_Bone from "../../assets/products_images/Chicken_Breast_with_Bone.png";
import turkey_wings from "../../assets/products_images/turkey_wings.png";
import shawarma_small from "../../assets/products_images/Shawarma_small.png";
import tighQuarter_small from "../../assets/products_images/TighQuarter_small.png";
import whole_turkey1 from "../../assets/products_images/whole_turkey1.png";
import turkey_breast1 from "../../assets/products_images/turkey-breast1.png";
import Topside_small from "../../assets/products_images/Topside_small.png";
import Silverside_small from "../../assets/products_images/Silverside_small.png";
import knuckle_small from "../../assets/products_images/knuckle_small.png";
import Hind_Quarter_small from "../../assets/products_images/Hind_Quarter_small.png";
import Four_Quarter_small from "../../assets/products_images/Four_Quarter_small.png";
import Panga_Fillets_small from "../../assets/products_images/Panga_Fillets_small.png";
import Panga_Steak_small from "../../assets/products_images/Panga_Steak_small.png";
import Rohu_Fish_small1 from "../../assets/products_images/Rohu_Fish_small1.png";
import Hake_small from "../../assets/products_images/Hake_small.png";
import Somon_small from "../../assets/products_images/Somon_small.png";

import sugar2 from "../../assets/products_images/sugar2.png";
import corn12 from "../../assets/products_images/corn12.png";
import soy_bean from "../../assets/products_images/soy-bean2.png";
import shaer from "../../assets/products_images/shaer.png";
import wheat from "../../assets/products_images/wheat_PNG80.png";

import alaf1 from "../../assets/products_images/alaf1.png";
import alaf2 from "../../assets/products_images/alaf2.png";
import alaf3 from "../../assets/products_images/Chickenـfeed.png";

function Products() {
  const [tabPosition, setTabPosition] = useState("top");
  const [tapActive, setTapActive] = useState(1);
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const { t } = useTranslation();
  let location = useLocation();
  const productsType = ["المنتجات المجمدة", "حبوب وبذور", "اعلاف"];

  const alafProducts = [
    {
      img: alaf1,
      title: t("Animalfeed"),
    },
    {
      img: alaf2,
      title: t("Alltypesofeed"),
    },
    {
      img: alaf3,
      title: t("Chickenfeed"),
    },
  ];

  const softProducts = [
    {
      img: sugar2,
      title: t("Sugar"),
    },
    {
      img: corn12,
      title: t("Corn"),
    },
    {
      img: soy_bean,
      title: t("SoyBeanMeal"),
    },
    {
      img: shaer,
      title: t("barley"),
    },
    {
      img: wheat,
      title: t("wheat"),
    },
  ];

  const frozenProducts = [
    {
      img: legQuarter_small,
      title: t("LegQuarter"),
    },
    {
      img: griller_small,
      title: t("Griller"),
    },
    {
      img: chicken_Breast_small,
      title: t("Breast"),
    },
    {
      img: drumstick,
      title: t("Drumstick"),
    },
    {
      img: breast_with_Bone,
      title: t("BoneinBreast"),
    },
    {
      img: turkey_wings,
      title: t("Wings"),
    },
    {
      img: shawarma_small,
      title: t("Shawarma"),
    },
    {
      img: tighQuarter_small,
      title: t("ThighQuarter"),
    },
    {
      img: whole_turkey1,
      title: t("WholeTurkey"),
    },
    {
      img: turkey_breast1,
      title: t("TurkeyBreast"),
    },
    {
      img: Topside_small,
      title: t("Topside"),
    },
    {
      img: Silverside_small,
      title: t("Silverside"),
    },
    {
      img: knuckle_small,
      title: t("Knuckle"),
    },
    {
      img: Hind_Quarter_small,
      title: t("HindQuarter"),
    },
    {
      img: Four_Quarter_small,
      title: t("ForeQuarter"),
    },
    {
      img: Panga_Fillets_small,
      title: t("PangaFillets"),
    },
    {
      img: Panga_Steak_small,
      title: t("PangaSteak"),
    },
    {
      img: Rohu_Fish_small1,
      title: t("RohuFish"),
    },
    {
      img: Hake_small,
      title: t("Hake"),
    },
    {
      img: Somon_small,
      title: t("Salmon"),
    },
  ];

  const setTapActiveClick = (tatNumber) => {
    setTapActive(tatNumber);
    console.log(tatNumber);
  };

  return (
    <div className="products">
      <div className="products_container">
        <h3>
          {location.pathname !== "/ar" ? t("ozkaya") : t("coProductsCo")}{" "}
          <span>
            {location.pathname !== "/ar" ? t("coProductsCo") : t("ozkaya")}
          </span>
        </h3>
        <div className="products_tap">
          <div className="tap" id="1" onClick={() => setTapActiveClick(1)}>
            <span className={tapActive == 1 ? "tapSpan_active" : "tapSpan"}>
              {t("FrozenProducts")}
            </span>
          </div>
          <div className="tap" id="2" onClick={() => setTapActiveClick(2)}>
            <span className={tapActive == 2 ? "tapSpan_active" : "tapSpan"}>
              {t("SoftCommodities")}
            </span>
          </div>
          <div className="tap" id="3" onClick={() => setTapActiveClick(3)}>
            <span className={tapActive == 3 ? "tapSpan_active" : "tapSpan"}>
              {t("Feed")}
            </span>
          </div>
        </div>
        <div
          className={
            tapActive == 1 ? "products_section" : "products_section_hide"
          }
        >
          {frozenProducts.map((product, index) => (
            <div className="product" key={index}>
              <img src={product.img} alt={product.title} />
              <p>{product.title}</p>
            </div>
          ))}
        </div>
        <div
          className={
            tapActive == 2 ? "products_section" : "products_section_hide"
          }
        >
          {softProducts.map((product, index) => (
            <div className="product" key={index}>
              <img src={product.img} alt={product.title} />
              <p>{product.title}</p>
            </div>
          ))}
        </div>
        <div
          className={
            tapActive == 3 ? "products_section" : "products_section_hide"
          }
        >
          {alafProducts.map((product, index) => (
            <div className="product" key={index}>
              <img src={product.img} alt={product.title} />
              <p>{product.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
