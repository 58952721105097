import React, { useEffect } from "react";
import "./home.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Navbar,
  HomeCom,
  About,
  Products,
  Services,
  Contact,
  Location,
} from "../../components";

function Home() {
  let location = useLocation();

  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (location.pathname === "/ar") {
      changeLanguage("ar");
    } else {
      changeLanguage("en");
    }
  }, [1]);

  return (
    <div className="scrollable-container">
      <div className="home">
        <Navbar />
        <section id="home">
          <HomeCom />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="products">
          <Products />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="location">
          <Location />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </div>
    </div>
  );
}

export default Home;
