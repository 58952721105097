import { BrowserRouter, Routes, Route } from "react-router-dom";
import './i18n';
import { Home } from "./pages";
import './global.scss';
import "./CustomScrollbar.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
          </Route>
          <Route path="/ar">
            <Route index element={<Home />} />
            {/* <Route path="/home" element={<Home />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
